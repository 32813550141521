#root {
  height: 100vh;
}



html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

* {
  /* Make scroll smooth */
  scroll-behavior: smooth;

  /* Make all transitions smooth with a default duration */
  transition: all 0.3s ease;
  /* 0.3s for smooth transitions */

  /* Ensure animations also have smooth timing */
  animation: all 0.3s ease;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  min-width: fit-content;
}

.root {
  box-sizing: border-box;
}


/* Custom Scrollbar */
/* Style for the entire scrollbar */
::-webkit-scrollbar {
  width: 8px;
  /* width of the vertical scrollbar */
  height: 8px;
  /* height of the horizontal scrollbar */
}

/* Style for the track (the area the thumb slides within) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Light gray background for the track */
  border-radius: 10px;
  /* Rounded corners for the track */
}

/* Style for the thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Dark gray color for the thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
  border: 3px solid #f1f1f1;
  /* Creates a border between thumb and track */
}

/* Style for when the thumb is hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Darker thumb on hover */
}

/* Style for the corner (where horizontal and vertical scrollbars meet) */
::-webkit-scrollbar-corner {
  background-color: transparent;
  /* Remove background at the corner */
}