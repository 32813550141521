// .Navbar-container {
//   align-items: center;
//   justify-content: space-between;

//   // width: 70vw;
//   //   background-color: black;
//   //   border: 10px solid red;
// }


// .main-container {
//   width: 100%;
//   height: 100vh;
//   position: "relative";
//   //   position: sticky;
// }

// .main-container::before {
//   content: "";
//   display: block;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0,
//       0,
//       0,
//       0.4);
//   /* Change the color and opacity as needed */
// }

// .AppBar {
//   height: 12vh;
//   display: flex;
//   width: 100%;
//   justify-content: center;
//   // sx={{ position: "absolute"

//   opacity: 2;
//   //   border-bottom: 1px solid white;
//   // border: 2px solid red;
// }

#main-container::after {
  // Navbar background black blur
  // content: "";
  // // display: block;
  // position: absolute;
  // // top: 0;
  // // left: 0;
  // width: 100%;
  // height: 100%;
  // background-color: rgba(0, 0, 0, 0.45);
  // background-blend-mode: multiply;
}

#main-container {
  // .header-image-background {
  //   position: absolute;
  //   left: 0;
  // }

  .header-image-background::before {
    content: "";
    // display: block;
    position: absolute;
    // top: 0;
    // left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.45);
    background-blend-mode: multiply;
  }

  .header-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  // For big screen
  .AppBar {
    height: 12vh;
    display: flex;
    width: 100%;
    justify-content: center;

    opacity: 2;
  }

  // For small screen
  .checked-AppBar {
    height: 100vh;
    // padding-bottom: calc(100vh - 265px);
    display: flex;
    background-color: rgb(17, 24, 39);
    z-index: 1;
    width: 100%;

    transition: height 0.5s ease-in-out, transform 0.5s ease-in-out;
    text-align: left;
  }

  .burger {
    main {
      height: 100vh;
      margin-top: 20vh;
    }

    label {
      position: absolute;
      top: 40px;
      right: 40px;
      height: 20px;
      width: 15px;
      z-index: 5;
    }

    input {
      position: absolute;
      opacity: 0;
      z-index: 6;

      top: 33px;
      right: 35px;

      height: 30px;
      width: 20px;

      cursor: pointer;
    }

    span {
      position: absolute;
      width: 100%;
      height: 2px;
      top: 50%;
      margin-top: -1px;
      left: 0;
      display: block;
      background: white;
      transition: 0.5s;
    }

    span:first-child {
      top: 3px;
    }

    span:last-child {
      top: 16px;
    }

    label:hover {
      cursor: pointer;
    }

    input:checked+label span {
      opacity: 0;
      top: 50%;
    }

    input:checked+label span:first-child {
      opacity: 1;
      transform: rotate(405deg);
    }

    input:checked+label span:last-child {
      opacity: 1;
      transform: rotate(-405deg);
    }

    input~nav {
      background: black;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
      z-index: 3;
      transition: 0.5s;
      transition-delay: 0.5s;
      overflow: hidden;
    }

    input~nav {
      text-align: center;
      position: absolute;
      right: 20%;
    }

    input~nav li {
      list-style-type: none;
      opacity: 0;
      transition: 0.5s;
      transition-delay: 0s;
    }

    input~nav a {
      text-decoration: none;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      font-family: sans-serif;
      display: block;
      padding: 30px;
    }

    input:checked~nav {
      height: 100%;
      transition-delay: 0s;
    }

    input:checked~nav li {
      opacity: 1;
      transition-delay: 0.5s;
    }
  }
}

@media screen and (max-width: 990px) {
  .AppBar {
    height: 10vh;
    display: flex;
    //   position: sticky;
    width: 100%;
    justify-content: center;
    background-color: rgb(17, 24, 39);
    transition: height 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 2;
    border-bottom: 0.1px solid gray;
    z-index: 1;
  }
}