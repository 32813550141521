/** @format */



/* 
:root {
  --card-height: 5vw;
  --card-margin: 2vw; 
  --card-top-offset: 0.5em;
  --numcards: 6; 
  --outline-width: 0px;

}  */


.card-0 {
  position: sticky;
  z-index: 0;
  width: 100%;
  height: 50vh;
  top: 0px;
  background-color: rgb(17, 24, 39);

}

#card-1 {
  --index: 1;
}

#card-2 {
  --index: 2;
}

#card-3 {
  --index: 3;
}

#card-4 {
  --index: 4;
}

#card-5 {
  --index: 5;
}

#card-6 {
  --index: 6;
}

.card:not(#card-0) {
  position: sticky;
  top: calc(20vh + 0px);
  /* border: 2px solid red; */

  z-index: 0;
  transform-origin: center center;
}

.card:nth-child(odd):not(#card-0) {
  transform: rotate(3deg);
  /* Slight rotation to the right */
}

.card:nth-child(even):not(#card-0) {
  transform: rotate(-3deg);
  /* Slight rotation to the left */
}

.card-content {
  width: 60%;
  margin: auto;
  background-color: white;
  color: #131212;
  border-radius: 2rem;
  overflow: hidden;
  display: grid;
  grid-template-areas: 'img text';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;

  align-items: stretch;
  padding: 1.4em;

  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
}

.card-content>div {
  grid-area: text;
  width: 95%;
  max-width: 800px;
  place-self: center;
  text-align: left;
  display: grid;
  gap: 1em;
  place-items: start;
}

/* .card-content>figure {
  grid-area: img;
  overflow: hidden;

  width: 320px;
}

.card-content>figure>img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 0.4em;
} */

h1 {
  font-weight: 300;
  font-size: 3rem;
  margin-bottom: 1rem;
  font-family: 'Cormorant Garamond', serif;
}

h2 {
  font-weight: bold;
  font-size: 2.5rem;
  font-family: 'Cormorant Garamond', serif;
  margin: 0;
}

p {
  font-weight: 300;
  line-height: 1.42;
  font-size: 1.1rem;
}

/* Animation */
@supports (animation-timeline: works) {
  @scroll-timeline cards-element-scrolls-in-body {
    source: selector(body);
    scroll-offsets: selector(#cards) start 1, selector(#cards) start 0;
    start: selector(#cards) start 1;
    end: selector(#cards) start 0;
    time-range: 4s;
  }

  .card {
    --index0: calc(var(--index) - 1);
    --reverse-index: calc(var(--numcards) - var(--index0));
    --reverse-index0: calc(var(--reverse-index) - 1);
  }

  .card__content {
    transform-origin: 50% 0%;
    will-change: transform;

    --duration: calc(var(--reverse-index0) * 1s);
    --delay: calc(var(--index0) * 1s);

    animation: var(--duration) linear scale var(--delay) forwards;
    animation-timeline: cards-element-scrolls-in-body;
  }

  @keyframes scale {
    to {
      transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
    }
  }
}