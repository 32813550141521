/* Variable Font: General Use */
@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/Inter-VariableFont_opsz\,wght.ttf') format('truetype');
  font-weight: 100 900;
  /* Supports thin to black */
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/Inter-Italic-VariableFont_opsz\,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
}

/* Static Fonts: For Specific Weights */
@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_18pt-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_24pt-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('./../assets/fonts/Inter/static/Inter_28pt-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}


/* Inter Normal 18 */

.font-inter-normal-18-100 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 100;
  font-style: normal;
}

.font-inter-normal-18-200 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 200;
  font-style: normal;
}

.font-inter-normal-18-300 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
}

.font-inter-normal-18-400 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
}

.font-inter-normal-18-500 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
}

.font-inter-normal-18-600 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
}

.font-inter-normal-18-700 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
}

.font-inter-normal-18-800 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 800;
  font-style: normal;
}

.font-inter-normal-18-900 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 900;
  font-style: normal;
}

/* Inter Italic 18 */

.font-inter-italic-18-100 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}

.font-inter-italic-18-200 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 200;
  font-style: italic;
}

.font-inter-italic-18-300 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
}

.font-inter-italic-18-400 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
}

.font-inter-italic-18-500 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
}

.font-inter-italic-18-600 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
}

.font-inter-italic-18-700 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 700;
  font-style: italic;
}

.font-inter-italic-18-800 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 800;
  font-style: italic;
}

.font-inter-italic-18-900 {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 900;
  font-style: italic;
}

/* Inter Normal 24 */

.font-inter-normal-24-100 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 100;
  font-style: normal;
}

.font-inter-normal-24-200 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 200;
  font-style: normal;
}

.font-inter-normal-24-300 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
}

.font-inter-normal-24-400 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
}

.font-inter-normal-24-500 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
}

.font-inter-normal-24-600 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
}

.font-inter-normal-24-700 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
}

.font-inter-normal-24-800 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
}

.font-inter-normal-24-900 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
}


/* Inter Italic 24 */

.font-inter-italic-24-100 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 100;
  font-style: italic;
}

.font-inter-italic-24-200 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 200;
  font-style: italic;
}

.font-inter-italic-24-300 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 300;
  font-style: italic;
}

.font-inter-italic-24-400 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 400;
  font-style: italic;
}

.font-inter-italic-24-500 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
}

.font-inter-italic-24-600 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 600;
  font-style: italic;
}

.font-inter-italic-24-700 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 700;
  font-style: italic;
}

.font-inter-italic-24-800 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 800;
  font-style: italic;
}

.font-inter-italic-24-900 {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 900;
  font-style: italic;
}

/* Inter Normal 28  */

.font-inter-normal-28-100 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 100;
  font-style: normal;
}

.font-inter-normal-28-200 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 200;
  font-style: normal;
}

.font-inter-normal-28-300 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 300;
  font-style: normal;
}

.font-inter-normal-28-400 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
}

.font-inter-normal-28-500 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
}

.font-inter-normal-28-600 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 600;
  font-style: normal;
}

.font-inter-normal-28-700 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 700;
  font-style: normal;
}

.font-inter-normal-28-800 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 800;
  font-style: normal;
}

.font-inter-normal-28-900 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 900;
  font-style: normal;
}

/* Inter Italic 28  */

.font-inter-italic-28-100 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 100;
  font-style: italic;
}

.font-inter-italic-28-200 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 200;
  font-style: italic;
}

.font-inter-italic-28-300 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 300;
  font-style: italic;
}

.font-inter-italic-28-400 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 400;
  font-style: italic;
}

.font-inter-italic-28-500 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 500;
  font-style: italic;
}

.font-inter-italic-28-600 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 600;
  font-style: italic;
}

.font-inter-italic-28-700 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 700;
  font-style: italic;
}

.font-inter-italic-28-800 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 800;
  font-style: italic;
}

.font-inter-italic-28-900 {
  font-family: 'Inter';
  font-size: 28px;
  font-weight: 900;
  font-style: italic;
}