// html,
// body {
//   max-width: 100%;
//   overflow-x: hidden;
//   overflow-y: scroll;
// }


#App {
  .header-image-background::before {
    content: "";
    // display: block;
    position: absolute;
    // top: 0;
    // left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.45);
    background-blend-mode: multiply;
  }

  .header-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}