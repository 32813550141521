/* Rotating Text - START */
.rotating-text {
  transform-origin: 100px 100px;
  /* Set rotation around the circle center */
  animation: rotateClockwise 20s linear infinite;
}

@keyframes rotateClockwise {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Rotating Text - END */



/* Fliping Card - START */
/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  width: 300px;
  height: 450px;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

//   .flip-card-img{
//     // object-fit: 'cover';
//     width:"100px";
//     height:"300px";
//      border: '10px solid red';
//   }

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  transition: transform 0.4s;
  transform-style: preserve-3d;
  transform-origin: center;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  border-radius: 20px;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;

  box-sizing: border-box;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  // background-color: #bbb;

  position: absolute;

  background-color: rgba(0,
      0,
      0,
      0.4);
  /* Change the color and opacity as needed */
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: rgb(17, 24, 39);
  color: white;
  text-align: left;
  transform: rotateY(180deg);

}

/* Fliping Card - END */



/* Nav animations - START */

.nav-item {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  /* Smooth animation */
}

.nav-item:hover {
  transform: scale(1.1);
  /* Slight zoom */
  color: #007bff;
  /* Optional: Change color on hover */
}


/* Nav animations - END */



/* Card animations - START */


// .main-card-animations {
//   // @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&family=DM+Mono:wght@400;500&display=swap');

//   :root {
//     --card-height: 40vw;
//     --card-margin: 4vw;
//     --card-top-offset: 1em;
//     --numcards: 4;
//     --outline-width: 0px;
//   }

//   // body {
//   //   background: #131212;
//   //   color: beige;
//   //   text-align: center;
//   //   padding-bottom: 20vh
//   // }

//   // header,
//   // main {
//   //   width: 80vw;
//   //   margin: 0 auto;
//   // }

//   // header {
//   //   height: 35vh;
//   //   display: grid;
//   //   place-items: center;
//   //   margin-bottom: 80px;
//   // }

//   #cards {
//     list-style: none;
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-template-rows: repeat(var(--numcards), var(--card-height));
//     gap: var(--card-margin);
//     padding-bottom: calc(var(--numcards) * var(--card-top-offset));
//     margin-bottom: var(--card-margin);
//   }

//   #card-1 {
//     --index: 1;
//   }

//   #card-2 {
//     --index: 2;
//   }

//   #card-3 {
//     --index: 3;
//   }

//   #card-4 {
//     --index: 4;
//   }

//   #card-5 {
//     --index: 4;
//   }

//   #card-6 {
//     --index: 4;
//   }

//   .card {
//     position: sticky;
//     top: 0;
//     padding-top: calc(var(--index) * var(--card-top-offset));
//   }

//   .card-content {
//     box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
//     // background: #fffaf2;
//     color: #131212;
//     border-radius: 2rem;
//     // overflow: hidden;
//     // display: grid;
//     // grid-template-areas: "text img";
//     // grid-template-columns: 1fr 1fr;
//     // grid-template-rows: auto;
//     // align-items: stretch;
//     // padding: 1.4em;
//   }

//   // .card-content>div {
//   //   grid-area: text;
//   //   width: 95%;
//   //   max-width: 800px;
//   //   place-self: center;
//   //   text-align: left;
//   //   display: grid;
//   //   gap: 1em;
//   //   place-items: start;
//   // }

//   // .card-content>figure {
//   //   grid-area: img;
//   //   overflow: hidden;
//   // }

//   // .card-content>figure>img {
//   //   width: 100%;
//   //   height: 100%;
//   //   object-fit: cover;
//   //   border-radius: .4em;
//   // }

//   // h1 {
//   //   font-weight: 300;
//   //   font-size: 3rem;
//   //   margin-bottom: 1rem;
//   //   font-family: 'Cormorant Garamond', serif;
//   // }

//   // h2 {
//   //   font-weight: bold;
//   //   font-size: 2.5rem;
//   //   font-family: 'Cormorant Garamond', serif;
//   //   margin: 0;
//   // }

//   // p {
//   //   font-weight: 300;
//   //   line-height: 1.42;
//   //   font-size: 1.1rem;
//   // }

//   /* Animation */
//   @supports (animation-timeline: works) {

//     @scroll-timeline cards-element-scrolls-in-body {
//       source: selector(body);
//       scroll-offsets:
//         selector(#cards) start 1,
//         selector(#cards) start 0;
//       start: selector(#cards) start 1;
//       end: selector(#cards) start 0;
//       time-range: 4s;
//     }

//     .card {
//       --index0: calc(var(--index) - 1);
//       --reverse-index: calc(var(--numcards) - var(--index0));
//       --reverse-index0: calc(var(--reverse-index) - 1);
//     }

//     .card__content {
//       transform-origin: 50% 0%;
//       will-change: transform;

//       --duration: calc(var(--reverse-index0) * 1s);
//       --delay: calc(var(--index0) * 1s);

//       animation: var(--duration) linear scale var(--delay) forwards;
//       animation-timeline: cards-element-scrolls-in-body;
//     }

//     @keyframes scale {
//       to {
//         transform:
//           scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
//       }
//     }
//   }

// }


/* Card animations - END */